import React, { FC } from 'react';
import { FluidObject, FixedObject } from 'gatsby-image';

import Author from 'components/Author';
import { Wrapper, Title, Info, Date, Cover, Content } from './styles';

type Props = {
  title: string;
  author: {
    name: string;
    photo: {
      childImageSharp: {
        fixed: FixedObject | FixedObject[];
      };
    };
  };
  date: string;
  cover: FluidObject | FluidObject[];
  content: string;
  className?: string;
};

const Post: FC<Props> = ({
  title,
  author,
  date,
  cover,
  content,
  className,
}) => (
  <Wrapper className={className}>
    <Title as="h1">{title}</Title>
    <Info>
      <Author name={author.name} cover={author.photo.childImageSharp.fixed} />
      <Date>{date}</Date>
    </Info>
    <Cover fluid={cover} draggable={false} />
    <Content dangerouslySetInnerHTML={{ __html: content }} />
  </Wrapper>
);

export default Post;
