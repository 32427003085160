import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { FluidObject, FixedObject } from 'gatsby-image';

import Head from 'views/Head';
import BlogCard from 'components/Card/Blog';
import {
  Wrapper,
  BackWrapper,
  Article,
  Recommended,
  RecommendedTitle,
  ArticleList,
  StyledCard,
} from './styles';
import Post from 'components/Post';
import BackBtn from 'components/Button/Back';

const ArticleTemplate: FC<ArticleQuery> = ({ data }) => {
  const { title, date, cover, author } = data.markdownRemark.frontmatter;
  const { html: content, excerpt } = data.markdownRemark;

  const { articles } = data;

  return (
    <>
      <BackWrapper>
        <BackBtn />
      </BackWrapper>
      <Wrapper>
        <Head
          title="Blog"
          description={excerpt}
          image={cover.publicURL}
          article
        />

        <Article
          as={Post}
          title={title}
          author={author}
          date={date}
          cover={cover.childImageSharp.fluid}
          content={content}
        />

        <Recommended>
          <RecommendedTitle>Podobne artykuły</RecommendedTitle>
          <ArticleList>
            {articles.edges.map(({ node }) => (
              <StyledCard
                as={BlogCard}
                key={node.id}
                to={node.fields.slug}
                fluid={node.frontmatter.cover.childImageSharp.fluid}
                title={node.frontmatter.title}
                text={node.excerpt}
              />
            ))}
          </ArticleList>
        </Recommended>
      </Wrapper>
    </>
  );
};

interface ArticleQuery {
  data: {
    markdownRemark: {
      html: string;
      excerpt: string;
      frontmatter: {
        title: string;
        date: string;
        author: {
          name: string;
          photo: {
            childImageSharp: {
              fixed: FixedObject | FixedObject[];
            };
          };
        };
        cover: {
          publicURL: string;
          childImageSharp: {
            fluid: FluidObject | FluidObject[];
          };
        };
      };
    };
    articles: {
      edges: {
        node: {
          id: string;
          fields: {
            slug: string;
          };
          excerpt: string;
          frontmatter: {
            title: string;
            cover: {
              childImageSharp: {
                fluid: FluidObject | FluidObject[];
              };
            };
          };
        };
      }[];
    };
  };
}

export const query = graphql`
  query ArticleTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      excerpt
      frontmatter {
        title
        date(formatString: "DD.MM.YYYY")
        author {
          name
          photo {
            childImageSharp {
              fixed(quality: 100, width: 44, height: 44) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        cover {
          publicURL
          childImageSharp {
            fluid(quality: 60, maxWidth: 680, maxHeight: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    articles: allMarkdownRemark(
      limit: 2
      sort: { fields: frontmatter___date, order: DESC }
      filter: { id: { ne: $id }, frontmatter: { type: { eq: "article" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          excerpt
          frontmatter {
            title
            cover {
              childImageSharp {
                fluid(quality: 50, maxWidth: 430) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ArticleTemplate;
