import styled from 'styled-components';
import { Subtitle } from 'components/Common';

export const BackWrapper = styled.div`
  max-width: 1366px;
  margin: 10rem auto 1rem;
  padding: 0 2rem;
`;

export const Wrapper = styled.div`
  max-width: 950px;
  margin: 0 auto;
`;

export const Article = styled.article`
  max-width: 720px;
  margin-bottom: 8rem;
`;

export const Recommended = styled.aside`
  margin: 0 auto 13.5rem;
  color: ${({ theme }) => theme.color.dark};
`;

export const RecommendedTitle = styled(Subtitle)`
  padding: 0 2rem;
  font-size: 3rem;
  text-align: left;
`;

export const ArticleList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ${({ theme }) => theme.mq.tablet} {
    justify-content: space-between;
  }
`;

export const StyledCard = styled.div`
  flex: 1 0 50%;
  min-width: 300px;
  padding: 0 2rem 4.2rem;

  ${({ theme }) => theme.mq.mobile} {
    padding: 0 3rem 4.2rem 2rem;
  }
`;
