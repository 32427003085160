import styled from 'styled-components';
import Img, { FixedObject } from 'gatsby-image';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface CoverProps {
  fixed: FixedObject | FixedObject[];
}

export const Cover = styled(Img)<CoverProps>`
  width: 44px;
  height: 44px;
  border-radius: 50%;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 1.2rem;
  font-size: 1.2rem;
`;

export const Name = styled.span`
  font-size: 1.4rem;
`;
