import React, { FC } from 'react';
import Img, { FluidObject } from 'gatsby-image';

import { Wrapper, Thumbnail, Content, Title, Text } from './styles';

type Props = {
  title: string;
  text: string;
  fluid: FluidObject | FluidObject[];
  to: string;
  className?: string;
};

const BlogCard: FC<Props> = ({ title, text, fluid, to, className }) => (
  <Wrapper to={to} className={className}>
    <Thumbnail as={Img} fluid={fluid} alt="" />
    <Content>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </Content>
  </Wrapper>
);

export default BlogCard;
