import styled from 'styled-components';
import { FluidObject } from 'gatsby-image';
import Link from 'components/Link';

export const Wrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  max-width: 480px;
  transition: transform 0.2s ease-in;
  outline: none;
  text-decoration: none;

  :focus,
  :hover {
    transform: scale(1.05);
  }
`;

interface ThumbnailProps {
  fluid: FluidObject | FluidObject[];
}

export const Thumbnail = styled.div<ThumbnailProps>`
  max-width: 430px;
  height: 200px;
  margin-bottom: 1rem;
  border-radius: 8px;
`;

export const Content = styled.div`
  flex: 1;
  min-height: 16.3rem;
  padding: 0 0.6rem;
  border-bottom: 1px solid #e2e7f0;
  color: ${({ theme }) => theme.color.dark};
  font-family: Poppins, sans-serif;
`;

export const Title = styled.h3`
  margin: 0 0 1.4rem;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 3rem;
`;

export const Text = styled.p`
  margin: 0 0 3rem;
  font-size: 1.3rem;
  line-height: 2.8rem;
`;
