import styled from 'styled-components';
import Img, { FluidObject } from 'gatsby-image';

export const Wrapper = styled.article`
  padding: 0 2rem;
  color: ${({ theme }) => theme.color.dark};
`;

export const Title = styled.h1`
  margin: 0 0 1rem;
  font-size: 3rem;
  line-height: 4.4rem;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const Date = styled.span`
  color: #a8b2be;
  font-size: 1.3rem;
`;

interface CoverProps {
  fluid: FluidObject | FluidObject[];
}

export const Cover = styled(Img)<CoverProps>`
  height: 335px;
  margin: 0 0 2.3rem;
  border-radius: 8px;
`;

export const Content = styled.div`
  h2 {
    margin: 0 0 1.2rem;
    font-size: 1.8rem;
    line-height: 4.4rem;
  }

  p {
    margin: 0 0 2.7rem;
    font-size: 1.3rem;
    line-height: 2.8rem;
  }
`;
