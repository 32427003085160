import React, { FC } from 'react';
import { FixedObject } from 'gatsby-image';

import { Wrapper, Cover, Body, Name } from './styles';

type Props = {
  name: string;
  cover: FixedObject | FixedObject[];
};

const Author: FC<Props> = ({ name, cover }) => (
  <Wrapper>
    <Cover fixed={cover} />
    <Body>
      <Name>{name}</Name>
      Simple Finance
    </Body>
  </Wrapper>
);

export default Author;
